
import {Options, Vue} from 'vue-class-component';
import basis from "@/components/class/basis";
import userApi from "@/api/dataList/userApi"
import yanZhengMa from "@/components/btn/yanZhengMa.vue";
import http from "@/api/http";
import din_shi from "@/api/din_shi"
@Options({
  components:{yanZhengMa},
  mixins:[basis],
  data() {
    return {
      userInfo: {},
      form:{}
    }
  },
  mounted() {
    userApi.jinDuUser().then((da) => {
      this.userInfo = da
    })
  },
  methods: {
    onSubmit() {
      http.post("/user/jinduindex/set_mobile",this.form).then((da)=>{
        if(da.code == 1){
          din_shi.success(da.msg).then(()=>{
            this.$router.push({name:'user_jin_du_index'})
          })
        }
      })
    }
  }

})

export default class jin_du_set_mobile extends Vue {

}
