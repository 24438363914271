
import {Options, Vue} from 'vue-class-component';
import http from "@/api/http";
import {Toast} from "vant"
import yan_zheng_ma_btn from "@/components/btn/yan_zheng_ma_btn.vue";
//手机验证码
@Options({
  components:{yan_zheng_ma_btn},
  props:{
    readonly:{
      type:Boolean,
      default() {
        return false
      }
    },
    url:String,
    value:String,
    label:{
      type:String,
      default(){
        return '手机'
      }
    }
  },
  data(){
    return {
      status:false,//是否显示倒计时
      mobile:'',
    }
  },
  methods:{
    post(){
      if(this.readonly){
        return
      }
      http.post(this.url,{
        mobile:this.mobile
      }).then(da=>{
        if(da.code ==1){
          Toast.success({
            message:da.msg
          })
          this.status = true
        }
      })
    }
  },
  watch:{
    "mobile"(){
      this.$emit("update:value",this.mobile)
    },
    "value"(){
      this.mobile = this.value
    }
  }
})

export default class yanZhengMa extends Vue {

}
