
import {Options, Vue} from 'vue-class-component';
import http from "@/api/http";
import {Toast} from "vant";
@Options({
  props:{
    url:String,
    value:String,
  },
  data(){
    return {
      status:false,//是否显示倒计时
    }
  },
  methods:{
    post(){
      if(this.readonly){
        return
      }
      http.post(this.url,{
        mobile:this.value
      }).then(da=>{
        if(da.code ==1){
          Toast.success({
            message:da.msg
          })
          this.status = true
        }
      })
    }
  }
})

export default class yan_zheng_ma_btn extends Vue {

}
